import React, { useEffect, useRef } from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { CSSPlugin } from "gsap/CSSPlugin"

import bgVid from "../../videos/yum-bg-gem-mosaic.mp4"

const YumSuperHero = () => {
  const blockEl = useRef(null)
  const videoEl = useRef(null)

  useEffect(() => {
    if (blockEl && blockEl.current && videoEl && videoEl.current) {
      window.setTimeout(function () {
        //gsap.registerPlugin(ScrollTrigger, CSSPlugin)

        // const blockElTrigger = {
        //   trigger:
        //   pin: true,
        //   start: "top top",
        //   end: "bottom top",
        //   scrub: 0.25,
        //   toggleActions: "play none none reset",
        //   invalidateOnRefresh: true,
        // }
        const video = videoEl.current
        let src = video.currentSrc || video.src
        console.log(video, src)

        /* Make sure the video is 'activated' on iOS */
        function once(el, event, fn, opts) {
          var onceFn = function (e) {
            el.removeEventListener(event, onceFn)
            fn.apply(this, arguments)
          }
          el.addEventListener(event, onceFn, opts)
          return onceFn
        }

        once(document.documentElement, "touchstart", function (e) {
          video.play()
          video.pause()
        })

        /* ---------------------------------- */
        /* Scroll Control! */

        gsap.registerPlugin(ScrollTrigger)

        let tl = gsap.timeline({
          defaults: { duration: 1 },
          scrollTrigger: {
            trigger: blockEl.current,
            start: "top top",
            end: "bottom bottom",
            scrub: true,
            pin: true,
          },
        })

        once(video, "loadedmetadata", () => {
          tl.fromTo(
            video,
            {
              currentTime: 0,
            },
            {
              currentTime: video.duration || 1,
            }
          )
        })

        /* When first coded, the Blobbing was important to ensure the browser wasn't dropping previously played segments, but it doesn't seem to be a problem now. Possibly based on memory availability? */
        setTimeout(function () {
          if (window["fetch"]) {
            fetch(src)
              .then((response) => response.blob())
              .then((response) => {
                var blobURL = URL.createObjectURL(response)

                var t = video.currentTime
                once(document.documentElement, "touchstart", function (e) {
                  video.play()
                  video.pause()
                })

                video.setAttribute("src", blobURL)
                video.currentTime = t + 0.01
              })
          }
        }, 1000)
      }, 50)
    }
  }, [])

  return (
    <Layout>
      <BlockWrapper block={{ block_width: "screen" }} blockPadding="pb-0">
        <div ref={blockEl} className="w-full h-[200vh] bg-primary">
          <video
            ref={videoEl}
            src={bgVid}
            playsinline="true"
            webkit-playsinline="true"
            preload="auto"
            muted="muted"
            className="w-full aspect-video"
          ></video>
        </div>
      </BlockWrapper>
    </Layout>
  )
}

export default YumSuperHero
